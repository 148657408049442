import { JOB_STATUS } from 'common/dist/constants/enums';
import React, { FC } from 'react';
import {
  Redirect,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useTimeTravel } from './hooks';
import TimeTravel from './TimeTravel';
import { useSettings, useSettingsHistory } from '../../../core/api/augurs';
import { useJobs } from '../../../core/api/jobs';
import { useActiveModel, useModels } from '../../../core/api/mlModels';
import Busy from '../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../../index/routes';
import { AugurCategory } from '../../molecules/augur-menu/types';

export type Props = {
  selectedPageCategory?: AugurCategory;
};

const LiveTimeTravel: FC<Props> = ({ selectedPageCategory }) => {
  const location = useLocation();
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const pathPrefix = useRouteMatch().url;

  const { singleSelection } = useTimeTravel();

  const { data: activeModel, isLoading: isActiveModelLoading } = useActiveModel(
    habitatCode,
    augurCode
  );
  const { data: activeSettings, isLoading: isActiveSettingsLoading } =
    useSettings(habitatCode, augurCode);

  const { data: jobs, isLoading: isJobsLoading } = useJobs(
    habitatCode,
    augurCode,
    JOB_STATUS.FINISHED
  );
  const { data: settingsHistory, isLoading: isSettingsHistoryLoading } =
    useSettingsHistory(habitatCode, augurCode);
  const { data: models, isLoading: isModelsLoading } = useModels(
    habitatCode,
    augurCode
  );

  if (
    isActiveModelLoading ||
    isActiveSettingsLoading ||
    isJobsLoading ||
    isSettingsHistoryLoading ||
    isModelsLoading
  )
    return <Busy isBusy={true} />;

  // when in selection mode, check whether the selected page category fits the selected job
  // redirect if this is not the case
  const explicitlySelectedJob = jobs.find(
    (job) => job.jobCode === singleSelection
  );
  if (
    singleSelection &&
    selectedPageCategory !== explicitlySelectedJob.jobType
  ) {
    return (
      <Redirect
        to={`${pathPrefix}/${explicitlySelectedJob.jobType}${location.search}`}
      />
    );
  }

  return (
    <TimeTravel
      entries={jobs.map((job) => ({
        code: job.jobCode,
        type: job.jobType,
        settingsCode: job.settingsCode,
        modelCode: job.modelCode,
        jobCode: job.jobCode,
        timestamp: job.createdAt,
        title: job.reportCode,
      }))}
      models={models}
      settingsHistory={settingsHistory}
      activeModelCode={activeModel.code}
      selectedPageCategory={selectedPageCategory}
    />
  );
};

export default LiveTimeTravel;
