import adminMsgs from 'common/dist/messages/admin';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import User from './User';
import { useUsersList } from '../../../../core/api/admin';
import Button from '../../../atoms/button/Button';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import * as routes from '../../../index/routes';
import Paging, { PagingProps } from '../../../molecules/paging/Paging';
import { usersRoutes } from '../routes';
import styles from '../styles.module.scss';

export const adminAddUserLink = `${usersRoutes.basePath}/${routes.app.admin.addUser}`;
const PAGE_SIZE = 20;

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  account: {
    name: string;
  };
  email: string;
  attributes?: {
    isTechnical: string[];
  };
  realmRoles: { name: string }[];
};

const Inner: FC<PagingProps> = ({ offset, search }) => {
  const { data: users, isLoading } = useUsersList(offset, PAGE_SIZE, search);

  const renderHeadline = () => {
    return (
      <div className={'ct-headline ct-row'}>
        <div className={'ct-col ct-col-200px'}>
          <FormattedMessage {...adminMsgs.msgUsersFirstName} />
        </div>
        <div className={'ct-col ct-col-200px'}>
          <FormattedMessage {...adminMsgs.msgUsersLastName} />
        </div>
        <div className={'ct-col ct-col-200px'}>
          <FormattedMessage {...adminMsgs.msgUsersEmail} />
        </div>
        <div className={'ct-col ct-col-120px ct-col-admin'}>
          <FormattedMessage {...adminMsgs.msgUsersIsAdmin} />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={styles.listContainer}>
        <div className={'ct-list'}>
          {renderHeadline()}
          {Array.from({ length: 10 }).map((_, i) => (
            <div key={i} className={'ct-row'}>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
              <div className={'ct-col ct-col-120px ct-col-admin'}>
                <LoadingPlaceholder width={'16px'} height={'16px'} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      <div className={'ct-list'}>
        {renderHeadline()}
        {users && users?.map((user) => <User {...user} key={user.id} />)}
      </div>
    </div>
  );
};

export const Header = (
  currentPage: number,
  offset: number,
  limit: number,
  totalItemsOrCurrentItems: number
) => (
  <span>
    <FormattedMessage
      {...adminMsgs.msgUsersPagingHeader}
      values={{ from: offset + 1, to: offset + totalItemsOrCurrentItems }}
    />
  </span>
);

// auxiliary hook to provide Paging component with list of data (e.g. for number of items)
const useData = (offset, search) =>
  useUsersList(offset, PAGE_SIZE, search).data ?? [];

const UsersList: FC = () => {
  return (
    <div className={styles.listPageParent}>
      <div className={styles.topButtonBar}>
        <Button
          color={'secondary'}
          label={adminMsgs.msgAddUser}
          linkTo={adminAddUserLink}
        />
      </div>
      <div className={styles.pagingWrapper}>
        <Paging
          itemsPerPage={PAGE_SIZE}
          Headline={Header}
          searchEnabled
          useData={useData}
          InnerComponent={Inner}
        />
      </div>
    </div>
  );
};

export default UsersList;
