import { ToBeRefined } from 'common/dist/types/todo_type';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import qs from 'qs';
import React, { FC, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { form } from './cloneRepository.form';
import StepPath from './StepPath';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import Busy from '../../../atoms/busy/Busy';
import Button from '../../../atoms/button/Button';
import { useSelectedDirPath } from '../../../workbench/hooks';
import { moduleDetailsLink, repositoryDetailsLink2 } from '../../routes';

type Props = {
  fetchRepoDetails: ToBeRefined;
  filledCompletely: boolean;
  cloneGitRepository: ToBeRefined;
  /** Code of the repository */
  repoCode: string;
  /** Derived from the first and last name of the currently logged in user */
  gitDisplayName: string;
  gitMail: string;
  activeBranch: string;
  repoDetails: {
    data: {
      repoName: string;
      repoFullName: string;
      name: string;
      repoType: string;
      codeCapsuleCode: string;
      appCode: string;
    };
    loading: boolean;
  };
  checkWhetherNotebookIsRunning: ToBeRefined;
  notebookRunning: {
    loading: boolean;
    loaded: boolean;
    isRunning: boolean;
    error: string;
  };
  notebookUser: ToBeRefined;
};

const CloneRepositoryWizard: FC<Props> = ({
  filledCompletely,
  cloneGitRepository,
  gitDisplayName,
  gitMail,
  repoCode,
  activeBranch,
  checkWhetherNotebookIsRunning,
  notebookUser,
  fetchRepoDetails,
  repoDetails,
  notebookRunning: { loading, isRunning },
}) => {
  const { group, repositoryName } = useRouteMatch<{
    group: string;
    repositoryName: string;
  }>().params;

  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const moduleCode = queryParams?.moduleCode as string | undefined;

  useEffect(() => {
    checkWhetherNotebookIsRunning(notebookUser, false);
  }, [checkWhetherNotebookIsRunning, notebookUser]);

  useEffect(() => {
    fetchRepoDetails(group, repositoryName);
  }, [fetchRepoDetails, group, repositoryName]);

  const selectedDirPathString = contentArrayToPath(useSelectedDirPath());

  function renderLoading() {
    return (
      <div className={'CollaborationSpace--content'}>
        <Busy isBusy />
      </div>
    );
  }

  function renderLoaded() {
    const { repoName, repoFullName, repoType, codeCapsuleCode, appCode, name } =
      repoDetails?.data || {};

    // Content that will be written into the repository.asr meta file of the cloned repository (locally in the private
    //  notebook space - not on the git server)
    const metaFileContent = JSON.stringify({
      repoCode,
      repoType,
      repoName,
      name,
      codeCapsuleCode,
      appCode,
      // Includes the group (=organization) as prefix to the repoName
      // TODO may be better to add owner.username, to avoid string manipulation. Would need to come from backend and be added while creating the repository
      //  although that is only useful to distinguish the gitea group (=organization = owner of a repo)
      repoFullName,
    });

    // repoName can be missing if only the git infos are available, but we still need a dir to clone into and its only(?!) used for that
    const repoNameSafe =
      repoName !== undefined ? repoName : repoFullName?.split('/')[1];
    const cancelButtonLink = moduleCode
      ? moduleDetailsLink(moduleCode) // Cloning a Module
      : repositoryDetailsLink2(group, repositoryName); // Cloning an App, Code Capsule or Plain Repository

    if (!isRunning) {
      return (
        <div className={'dialog-container clone-repository-wizard'}>
          <div className={'dialog-inner-container'}>
            <p className={'dialog-headline'}>Clone Repository</p>
            <div className={'start-workbench-parent dialog-button-container'}>
              <p>
                Your Workbench isn't running yet. Please start it first before
                you can clone your repository.
              </p>
              <div className={'buttons'}>
                <Button
                  label='Cancel'
                  color='white'
                  linkTo={cancelButtonLink}
                />

                <Button
                  label='Go to Workbench'
                  color='secondary'
                  linkTo={workbenchRoutes.basePath}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'dialog-container clone-repository-wizard'}>
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>Clone Repository</p>
          <form
            onKeyPress={(e) => {
              if (e.which === 13) e.preventDefault();
            }}
          >
            <StepPath />
            <div className={'dialog-button-container'}>
              <Button label='Cancel' color='white' linkTo={cancelButtonLink} />

              <Button
                label='Finish'
                color='secondary'
                linkTo={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`}
                disabled={!isRunning || !filledCompletely}
                onClick={() => {
                  if (filledCompletely && isRunning) {
                    cloneGitRepository(
                      selectedDirPathString,
                      repoNameSafe,
                      repoFullName,
                      gitDisplayName,
                      gitMail,
                      metaFileContent,
                      activeBranch
                    );
                  }
                }}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  const { loading: loadingDetails } = repoDetails || {};
  if (loading || loadingDetails) return renderLoading();
  else return renderLoaded();
};

// @ts-ignore
export default reduxForm(form)(CloneRepositoryWizard);
