import { defineMessages } from 'react-intl';

export default defineMessages({
  habitatAdd: {
    id: 'habitat.add',
    defaultMessage: 'Add Habitat',
  },
  habitatDeleteTitle: {
    id: 'habitat.modal.delete.title',
    defaultMessage: 'Delete Habitat',
  },
  habitatDeleteDescription: {
    id: 'habitat.modal.delete.description',
    defaultMessage:
      'You are going to delete the habitat {nameBold}. Please type in {nameCode} and confirm if you are sure.',
  },
  habitatAddHeader: {
    id: 'habitat.wizard.add.header',
    defaultMessage: 'Create New Habitat',
  },
  habitatUpdateHeader: {
    id: 'habitat.wizard.update.header',
    defaultMessage: 'Update Habitat',
  },
  habitatWizardNameTitle: {
    id: 'habitat.wizard.name.title',
    defaultMessage: 'Habitat Name',
  },
  habitatWizardNameDescription: {
    id: 'habitat.wizard.name.description',
    defaultMessage: 'Add a Name for the Habitat',
  },
  habitatWizardNameErrorLength: {
    id: 'habitat.wizard.name.error.length',
    defaultMessage: 'Habitat name must be between 3 and 65 characters long',
  },
  habitatWizardNameErrorSymbols: {
    id: 'habitat.wizard.name.error.symbols',
    defaultMessage: 'It can contain only alphanumeric symbols and spaces',
  },
  habitatWizardNameErrorMissing: {
    id: 'habitat.wizard.name.error.missing',
    defaultMessage: 'Missing Habitat name',
  },
  habitatWizardNameErrorAlreadyExists: {
    id: 'habitat.wizard.name.error.already_exists',
    defaultMessage: 'This name already exists',
  },
});
