import msgsModules from 'common/dist/messages/modules';
import msgsRepository from 'common/dist/messages/repository';
import { ModulesParameters } from 'common/dist/types/module';
import _ from 'lodash';
import qs from 'qs';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import { useModuleCreators, useModules } from '../../../../core/api/modules';
import { useUserSummaries } from '../../../../core/api/users';
import {
  locationWithRemovedQuery,
  locationWithUpdatedQuery,
} from '../../../../utils';
import Button from '../../../atoms/button/Button';
import DropdownSelectInput, {
  Option,
} from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import ModuleCard from '../../../molecules/module-card/ModuleCard';
import Paging, { PagingProps } from '../../../molecules/paging/Paging';
import { moduleDetailsLink, modulesCreateLink } from '../../routes';
import { DEFAULT_PAGE_SIZE } from '../repositories/util';

export interface ModulesProps {}

export type PagingParams = Pick<
  ModulesParameters,
  'has_repository' | 'has_versions' | 'is_stock' | 'created_by'
>;

const Modules: FC<ModulesProps> = () => {
  const history = useHistory();
  const { has_repository, has_versions, created_by, is_stock }: PagingParams =
    qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  const useData = (offset?: number, search?: string) =>
    useModules({
      offset,
      limit: DEFAULT_PAGE_SIZE,
      search,
      has_repository,
      has_versions,
      created_by,
      is_stock,
    }).data || [];
  return (
    <div className={'CollaborationSpace--content'}>
      <Paging
        itemsPerPage={DEFAULT_PAGE_SIZE}
        searchEnabled
        Headline={(currentPage, offset, limit, totalItemsOrCurrentItems) => (
          <Headline
            currentPage={currentPage}
            offset={offset}
            limit={limit}
            totalItemsOrCurrentItems={totalItemsOrCurrentItems}
          />
        )}
        useData={useData}
        InnerComponent={(props: PagingProps) => <InnerComponent {...props} />}
      />
    </div>
  );
};

const Headline: FC<{
  currentPage: number;
  offset: number;
  limit: number;
  totalItemsOrCurrentItems?: number;
}> = () => {
  const intl = useIntl();
  return (
    <div className={styles.Headline}>
      <Button
        color={'secondary'}
        linkTo={modulesCreateLink()}
        label={intl.formatMessage(msgsRepository.createButton, {
          type: 'Module',
        })}
      />
      <div className={styles.Filters}>
        <FilterUseCase />
        <FilterCreator />
      </div>
    </div>
  );
};

const FilterUseCase: FC = () => {
  const options = [
    { label: 'Augur', value: 'has_versions' },
    { label: 'Template', value: 'has_repository' },
  ];
  const history = useHistory();
  return (
    <div>
      <DropdownSelectInput
        options={options}
        onChange={(option: Option) => {
          if (!option) {
            history.push(
              locationWithRemovedQuery(history.location, [
                'has_versions',
                'has_repository',
              ])
            );
          } else if (option.value === 'has_versions') {
            history.push(
              locationWithRemovedQuery(
                locationWithUpdatedQuery(history.location, {
                  has_versions: 'true',
                }),
                ['has_repository']
              )
            );
          } else if (option.value === 'has_repository') {
            history.push(
              locationWithRemovedQuery(
                locationWithUpdatedQuery(history.location, {
                  has_repository: 'true',
                }),
                ['has_versions']
              )
            );
          }
        }}
        placeholder={msgsModules.overviewFilterUseCase}
        appearance={'small'}
        value={options.filter((o) =>
          Boolean(
            qs.parse(location.search, { ignoreQueryPrefix: true })[o.value]
          )
        )}
        clearable
      />
    </div>
  );
};

const FilterCreator: FC = () => {
  const options = [{ label: 'AltaSigma', value: 'AltaSigma' }];
  const creators = useModuleCreators();
  const userSummaries = useUserSummaries(creators.data || []);
  // Have to zip, to remember the reactQuery keys (=userId), which can't be recovered from the result
  _.zip(userSummaries, creators.data || []).forEach(([userSummary, userId]) => {
    if (userSummary.isSuccess) {
      options.push({
        label: `${userSummary.data.firstName || ''} ${
          userSummary.data.lastName || ''
        }`,
        value: userId,
      });
    }
  });
  const history = useHistory();
  return (
    <div className={styles.Dropdown}>
      <DropdownSelectInput
        options={options}
        onChange={(option: Option) => {
          if (!option) {
            history.push(
              locationWithRemovedQuery(history.location, [
                'is_stock',
                'created_by',
              ])
            );
          } else if (option.value === 'AltaSigma') {
            history.push(
              locationWithRemovedQuery(
                locationWithUpdatedQuery(history.location, {
                  is_stock: 'true',
                }),
                ['created_by']
              )
            );
          } else {
            history.push(
              locationWithRemovedQuery(
                locationWithUpdatedQuery(history.location, {
                  created_by: option.value,
                }),
                ['is_stock']
              )
            );
          }
        }}
        placeholder={msgsModules.overviewFilterCreator}
        appearance={'small'}
        value={options.filter((o) => {
          const params = qs.parse(location.search, { ignoreQueryPrefix: true });
          if (o.value === 'AltaSigma' && params['is_stock']) {
            return true;
          } else {
            return params['created_by'] === o.value;
          }
        })}
        clearable
      />
    </div>
  );
};

const InnerComponent: FC<PagingProps> = ({ offset, search }) => {
  const history = useHistory();
  const { has_repository, has_versions, created_by, is_stock }: PagingParams =
    qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  const modules =
    useModules({
      offset,
      limit: DEFAULT_PAGE_SIZE,
      search,
      has_repository,
      has_versions,
      created_by,
      is_stock,
    }).data || [];
  return (
    <div className={styles.elementCardGrid}>
      {modules.map((module) => (
        <Link
          key={module.code}
          to={moduleDetailsLink(module.code)}
          style={{ textDecoration: 'none' }}
        >
          <ModuleCard {...module} />
        </Link>
      ))}
    </div>
  );
};

export default Modules;
