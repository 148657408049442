import archiveMsgs from 'common/dist/messages/augurs.modelArchive';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import { useSetActiveModel } from '../../../core/api/mlModels';
import Button from '../../atoms/button/Button';
import { FormattedDateTime } from '../../atoms/formatted-date-time/FormattedDateTime';
import Table from '../../molecules/table/Table';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';

export type ModelTableEntry = {
  modelCode: string;
  activeSince: Date;
  activeUntil: Date;
};

type Props = {
  modelTableEntries: ModelTableEntry[];
  habitatCode: string;
  augurCode: string;
};

const ModelsTable: FC<Props> = (props) => {
  const { modelTableEntries, augurCode, habitatCode } = props;

  const setActiveModelMutation = useSetActiveModel(habitatCode, augurCode);

  const [modal, setShowModal] = useState({ show: false, modelCode: null });

  const renderColumns = [
    {
      key: 'modelCode',
      renderHeader: () => <FormattedMessage {...archiveMsgs.msgCode} />,
      renderCell: (modelCode: string) => <span>{modelCode}</span>,
    },
    {
      key: 'activeSince',
      renderHeader: () => <FormattedMessage {...archiveMsgs.msgActiveSince} />,
      renderCell: (activeSince: Date) =>
        activeSince && <FormattedDateTime date={activeSince} />,
    },
    {
      key: 'activeUntil',
      renderHeader: () => <FormattedMessage {...archiveMsgs.msgActiveUntil} />,
      renderCell: (activeUntil: Date) =>
        activeUntil && <FormattedDateTime date={activeUntil} />,
    },
    {
      key: 'isActive',
      renderHeader: () => <FormattedMessage {...archiveMsgs.msgStatus} />,
      renderCell: (isActive: boolean, modelTableEntry: ModelTableEntry) => {
        if (!modelTableEntry.activeUntil) {
          return (
            <div className={styles.activateCol}>
              <FormattedMessage {...archiveMsgs.msgActive}>
                {(text) => <span className={styles.activeModel}>{text}</span>}
              </FormattedMessage>
            </div>
          );
        } else {
          return (
            <div className={styles.activateCol}>
              <Button
                color={'primary'}
                label={archiveMsgs.msgActivate}
                onClick={() =>
                  setShowModal({
                    show: true,
                    modelCode: modelTableEntry.modelCode,
                  })
                }
                disabled={!modelTableEntry.activeUntil}
                className={styles.activateButton}
              />
            </div>
          );
        }
      },
    },
  ];

  return (
    <div>
      <Table
        data={modelTableEntries}
        // @ts-ignore FIXME-CM
        renderColumns={renderColumns}
        striped
        verticalAlignMiddle
        showHeader
        borderAround
      />

      <ConfirmationModal
        show={modal.show}
        hideModal={() => setShowModal({ show: false, modelCode: null })}
        buttonConfirmAction={() => {
          setActiveModelMutation.mutate(modal.modelCode);
        }}
        payload={{}}
        secure={false}
        description={{
          ...archiveMsgs.msgConfirmationModal,
          values: {
            code: modal.modelCode,
            strong: (bold) => <span style={{ fontWeight: 500 }}>{bold}</span>,
          },
        }}
      />
    </div>
  );
};

export default ModelsTable;
