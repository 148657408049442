import msgsForm from 'common/dist/messages/form';
import moduleMsgs from 'common/dist/messages/modules';
import notificationMsgs from 'common/dist/messages/notifications';
import { ModuleVersion } from 'common/dist/types/moduleVersion';
import React, { FC } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';

import vars from '../../../../../scss/base/var.module.scss';
import { useBuildModule, useModuleByCode } from '../../../../core/api/modules';
import * as CollabApi from '../../../../core/api/workbench/collab';
import * as NOTIFICATION_TYPES from '../../../../core/notifications';
import { sendNotification } from '../../../../redux/modules/notifications.module';
import { useAppDispatch } from '../../../../store/store';
import Busy from '../../../atoms/busy/Busy';
import { ButtonProps } from '../../../atoms/button/Button';
import { IntlTextInputLine } from '../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import BubbleStep from '../../../molecules/bubble-step/BubbleStep';
import Table from '../../../molecules/table/Table';
import Wizard from '../../../pages/wizard/Wizard';
import { moduleDetailsLink, overviewModulesLink } from '../../routes';
import { getTag, versionsRenderColumns } from '../common';

export type BuildModuleFormData = {
  moduleCode: string;
  repositoryCode: string;
  moduleVersionNumber: string;
};
export type Props = {
  moduleCode: string;
  repoGroup: string;
  repoName: string;
};

const BuildModuleWizard: FC<Props> = (props) => {
  const { moduleCode, repoGroup, repoName } = props;

  const history = useHistory();
  const { isInitialLoading, data: module } = useModuleByCode(moduleCode);
  const buildModuleHook = useBuildModule(moduleCode);
  //Use Hook to load the module versions

  const sortedVersions = module?.versions || [];
  if (sortedVersions)
    sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  const nextVersion = getTag(sortedVersions);

  const methods = useForm<BuildModuleFormData>({
    mode: 'all',
    values: {
      moduleCode: moduleCode,
      repositoryCode: undefined,
      moduleVersionNumber: nextVersion ? nextVersion : undefined,
    },
  });

  const { isValid, isDirty, isSubmitting } = useFormState(methods);
  const checkVersions = (value: string) => {
    const exists = (module?.versions || [])
      .map((version) => version.number)
      .includes(value);
    return exists ? 'Please enter a tag different from existing tags' : true;
  };
  const renderVersions = () => {
    if (isInitialLoading) {
      return <ReactLoading type={'cylon'} color={vars.colorPrimary} />;
    } else if (sortedVersions && sortedVersions.length > 0) {
      return (
        <div className={'tags-container'}>
          <Table<ModuleVersion>
            data={sortedVersions}
            renderColumns={versionsRenderColumns}
            showHeader={true}
            striped={true}
          />
        </div>
      );
    } else {
      return (
        <div>
          <span>There are no Tags for this Module yet</span>
        </div>
      );
    }
  };

  if (methods.formState.isSubmitSuccessful && !module?.code) {
    history.push(overviewModulesLink());
  } else if (methods.formState.isSubmitSuccessful && module?.code) {
    history.push(moduleDetailsLink(module.code));
  }
  const buttons: ButtonProps[] = [
    {
      color: 'white',
      label: msgsForm.cancel,
      onClick: () => {
        history.goBack();
      },
    },
    {
      color: 'secondary',
      type: 'submit',
      label: msgsForm.submit,
      disabled: !isValid,
      isBusy: isSubmitting,
    },
  ];

  const dispatch = useAppDispatch();
  const onSubmit = async (data: BuildModuleFormData) => {
    if (isValid) {
      const { response, error } = await CollabApi.fetchRepoDetails(
        repoGroup,
        repoName
      );
      if (typeof response === 'string') {
        console.error('Unexpected response', response);
        return;
      }
      if (response) {
        data.repositoryCode = response.code;
      }
      await buildModuleHook
        .mutateAsync(data)
        .then(() => {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleBuildSuccess.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleBuildSuccess.defaultMessage,
              NOTIFICATION_TYPES.event
            )
          );
        })
        .catch((e) => {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleBuildFailure.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleBuildFailure.defaultMessage,
              NOTIFICATION_TYPES.error
            )
          );
        });
    }
  };

  if (isInitialLoading) {
    return <Busy isBusy={true} />;
  } else
    return (
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        onKeyPress={(e) => {
          if (e.which === 13) e.preventDefault();
        }}
        style={{ height: '100%' }}
      >
        <Wizard
          headline={moduleMsgs.moduleBuildWizardHeadline}
          buttons={buttons}
        >
          <Controller
            name={'moduleVersionNumber'}
            control={methods.control}
            rules={{
              validate: checkVersions,
              required: 'Please enter a valid version.',
              maxLength: {
                value: 128,
                message: 'Please enter a tag with at maximum 128 characters',
              },
              pattern: {
                value: /^[a-zA-Z0-9\.\-_]+$/,
                message: 'Please enter a valid tag',
              },
            }}
            render={({ field, fieldState }) => {
              const { ref, ...rest } = field; // extract ref to pass as inputRef
              return (
                <BubbleStep
                  description={moduleMsgs.moduleBuildTagDescription}
                  isError={fieldState.isDirty && !!fieldState.error}
                  isValid={fieldState.isDirty && !fieldState.invalid}
                  stepNumber={1}
                  title={moduleMsgs.moduleBuildTagTitle}
                >
                  <IntlTextInputLine
                    label={'Enter a Version number'}
                    placeholder={'0.0.1'}
                    {...rest}
                    {...fieldState}
                    inputRef={ref}
                    error={fieldState.error?.message}
                  />
                  <div className={'existing-tags-parent'}>
                    <div className={'tags-headline'}>
                      Existing Tags for this Module
                    </div>
                    {renderVersions()}
                  </div>
                </BubbleStep>
              );
            }}
          />
        </Wizard>
      </form>
    );
};
export default BuildModuleWizard;
