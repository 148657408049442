import augurSettingsMessages from 'common/dist/messages/augurs.settings';
import commonMessages from 'common/dist/messages/common';
import { GeneralConfiguration } from 'common/dist/types/moduleVersion';
import React, { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FiArchive } from 'react-icons/fi';
import { LuRefreshCw } from 'react-icons/lu';
import { useIntl } from 'react-intl';

import styles from './styles.module.scss';
import { useModules } from '../../../core/api/modules';
import Button from '../../atoms/button/Button';
import ExpandCollapseIcon from '../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import AttributesInput from '../../atoms/react-hook-form-input-elements/attributes-input/AttributesInput';
import ResourceInput, {
  jobs,
} from '../../atoms/react-hook-form-input-elements/resource-input/ResourceInput';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import ModuleType from '../../molecules/module-type/ModuleType';
import GenericCollapsibleContainer from '../../organisms/collapsible-container/generic/GenericCollapsibleContainer';
import { AugurSettingsForm } from '../augur/utils/augurSettings.form';

export type Props = {
  moduleTypeSelectable: boolean;
  generalConfiguration?: GeneralConfiguration;
  form?: UseFormReturn<AugurSettingsForm, 'AugurSettingsForm'>;
  onArchiveAugur?: (archive: boolean) => void;
  archived?: boolean;
  isArchiveButtonLoading?: boolean;
};

const GeneralSettings: FC<Props> = ({
  moduleTypeSelectable,
  generalConfiguration,
  form,
  onArchiveAugur,
  archived,
  isArchiveButtonLoading,
}) => {
  const { control } = form;
  //We need to set the context for the complex key situation here
  control._options.context = 'AugurSettingsForm';

  const intl = useIntl();

  const { data, isInitialLoading } = useModules();
  const modules = (data || []).filter((module) => module.versions.length > 0);
  return (
    <div className={styles.container}>
      {onArchiveAugur && (
        <Button
          color={archived ? 'secondary' : 'orange'}
          onClick={() => {
            onArchiveAugur(!archived);
          }}
          label={archived ? 'Restore' : 'Archive'}
          className={styles.archiveButton}
          Icon={archived ? LuRefreshCw : FiArchive}
          isBusy={isArchiveButtonLoading}
        />
      )}
      <div className={styles.entry}>
        <span className={styles.title}>Augur Name</span>
        <Controller
          name={'general.augurName' as const}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef
            return (
              <IntlTextInputLine
                placeholder={intl.formatMessage(
                  augurSettingsMessages.augurNamePlaceholder
                )}
                {...rest}
                {...fieldState}
                inputRef={ref}
                error={fieldState.error?.message}
              />
            );
          }}
        />
      </div>

      <div className={styles.entry}>
        <span className={styles.title}>Module</span>

        <Controller
          name={'general.module' as const}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field; // extract ref to pass as inputRef

            const selectableModules = moduleTypeSelectable
              ? modules
              : modules.filter(
                  (module) => module.code === rest.value?.moduleCode
                );
            return (
              <ModuleType
                modules={selectableModules}
                loading={isInitialLoading}
                blurOnClick
                {...rest}
                {...fieldState}
                inputRef={ref}
              />
            );
          }}
        />
      </div>

      <GenericCollapsibleContainer
        title={intl.formatMessage(commonMessages.advanced)}
        initialCollapsed
        renderHeader={(title: string, collapsed: boolean) => (
          <div className={styles.advancedSectionHeader}>
            <div className={styles.collapseIcon}>
              <ExpandCollapseIcon isExpanded={!collapsed} onClick={() => {}} />
            </div>
            <span className={styles.title}>{title}</span>
          </div>
        )}
        renderBody={() => (
          <>
            <div className={styles.entry}>
              <span className={styles.title}>Resources</span>
              <ResourceInput
                jobs={jobs}
                generalConfiguration={generalConfiguration}
                //@ts-ignore FIXME-CM
                form={form}
              />
            </div>
            <div className={styles.entry}>
              <span className={styles.title}>Attributes</span>
              <AttributesInput />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default GeneralSettings;
