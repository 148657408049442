import classNames from 'classnames';
import commonMessages from 'common/dist/messages/common';
import _ from 'lodash';
import React, {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FiCheck } from 'react-icons/fi';

import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';

export type WizardPageInfo = {
  id: string;
  title: string;
  error?: string;
};

export type Props = {
  pages: WizardPageInfo[];
  wizardHeadline?: string;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit: () => void;
  /** Hide the submit button for this wizard (makes sense if the Wizard will be extended
   *   dynamically. And while the wizard is filled, the submit button is hidden)
   */
  noNextOrSubmitButton?: boolean;
  /** Is the form currently submitting? Causes the "submit" button to bubble */
  isSubmitting?: boolean;
  isValid: boolean;
  isValidating: boolean;
  renderContent: (id: string) => ReactElement;
};

const MultiPageWizard: FC<Props> = ({
  wizardHeadline,
  onDelete,
  onCancel,
  onSubmit,
  isValid,
  isValidating,
  isSubmitting,
  noNextOrSubmitButton,
  pages,
  renderContent,
}) => {
  const [activePage, setActivePage] = useState(0);
  const [showShadowTop, setShowShadowTop] = useState(false);
  const [showShadowBottom, setShowShadowBottom] = useState(false);

  const contentRef = useRef<HTMLDivElement>();
  function measureBodyHeight() {
    const { clientHeight, scrollTop, scrollHeight } = contentRef.current;
    const higherThanContainer = scrollHeight > clientHeight;

    const TOLERANCE = 3;
    const showShadowTop = higherThanContainer && scrollTop > TOLERANCE;
    const showShadowBottom =
      higherThanContainer &&
      scrollTop < scrollHeight - clientHeight - TOLERANCE;

    setShowShadowTop(showShadowTop);
    setShowShadowBottom(showShadowBottom);
  }
  useEffect(() => {
    if (contentRef.current) measureBodyHeight();
  }, [contentRef.current]);

  const nPages = pages.length;

  function renderPagesInfo() {
    return (
      <div className={styles.pageInfo}>
        {_.range(nPages).map((i) => {
          return (
            <Fragment key={i}>
              <span
                key={i}
                className={classNames(styles.pageBubble, {
                  [styles.pageBubbleDone]: i < activePage,
                  [styles.pageBubbleCurrent]: i === activePage,
                  [styles.pageBubbleUpcoming]: i > activePage,
                })}
              >
                {i < activePage && <FiCheck size={12} />}
              </span>
              {i + 1 < nPages && (
                <span className={classNames(styles.pageBubbleConnection)} />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }

  function renderFooter() {
    const pageTitle = pages[activePage].title;
    const pageErrors = pages[activePage].error;

    return (
      <div className={styles.footerContainer}>
        {showShadowBottom && <div className={styles.shadowBodyBottom} />}
        <div className={styles.buttonsLeft}>
          {nPages > 1 && (
            <Button
              color={'primary'}
              label={'Previous'}
              disabled={activePage === 0 || isSubmitting}
              onClick={() => setActivePage((activePage) => activePage - 1)}
            />
          )}
          <Button
            color={'white'}
            label={commonMessages.cancel}
            onClick={() => onCancel?.()}
          />
          {onDelete && (
            <Button
              color={'red'}
              label={commonMessages.delete}
              onClick={() => {
                onDelete();
              }}
            />
          )}
        </div>

        <div className={styles.infoCenter}>
          {nPages && renderPagesInfo()}

          {pageTitle && <span className={styles.pageTitle}>{pageTitle}</span>}
        </div>

        {noNextOrSubmitButton ? (
          <div className={styles.buttonsRightPlaceholder} />
        ) : (
          <div className={styles.buttonsRight}>
            {activePage < nPages - 1 ? (
              <Button
                color={'primary'}
                className={styles.buttonNext}
                label={'Next'}
                // This also disables while asyncValidating
                disabled={!!pageErrors}
                isBusy={isValidating}
                // Don't wrap in handleSubmit, which would automatically call (async) validation since we would need
                // to only validate the fields on the current page which is not very well-supported by asyncValidate
                onClick={() => setActivePage((activePage) => activePage + 1)}
              />
            ) : (
              <Button
                color={'secondary'}
                className={styles.buttonSubmit}
                label={commonMessages.submit}
                // With onBlur fields, this will often validate twice 1. for the onBlur of the field 2. again for the whole form
                disabled={!isValid || isSubmitting}
                // Can't set isBusy={... || Boolean(asyncValidating)} like in the other button because that also disables it
                // Scenario: 1. You are inside a text field with asyncValidation that validates onBlur 2. You click this submit button
                // What happens: The async validation is triggered before the onClick can be called
                isBusy={isSubmitting}
                onClick={onSubmit}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.multiPageWizard}>
      {wizardHeadline && (
        <div className={styles.headlineContainer}>
          <span>{wizardHeadline}</span>
          {showShadowTop && <div className={styles.shadowBodyTop} />}
        </div>
      )}
      <div
        className={classNames(styles.bodyContainer)}
        ref={contentRef}
        onScroll={measureBodyHeight}
      >
        {renderContent(pages[activePage].id)}
      </div>
      {renderFooter()}
    </div>
  );
};

export default MultiPageWizard;
