import { JOB_STATUS } from 'common/dist/constants/enums';
import React, { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useSelectedAugurPage } from './hooks';
import styles from './styles.module.scss';
import { AugurReport, AugurSettings, ModuleConfiguration } from './type';
import { pickGeneralConfigurationResources } from './utils/transformation';
import ViewAugur from './ViewAugur';
import {
  useAugur,
  useSettings,
  useUpdateAugur,
  useUpdateSettings,
} from '../../../core/api/augurs';
import { useJobs } from '../../../core/api/jobs';
import { useActiveModel } from '../../../core/api/mlModels';
import { useModules } from '../../../core/api/modules';
import { useReportsByCode } from '../../../core/api/reports';
import { event } from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../../index/routes';
import OverlayAugurDetails from '../../organisms/sub-header/overlay-augur-details/OverlayAugurDetails.container';
import {
  getFirstActiveJobCodeOfCurrentCategory,
  getTimeTravelEntryFromJob,
} from '../../organisms/time-travel/helpers';
import { useTimeTravelHelpers } from '../../organisms/time-travel/hooks';
import MainContainer from '../main-container/MainContainer';

// TODO create stories component
/**
 * Responsible for fetching jobs and filtering them for currently displayed reports.
 */
export const LiveAugur: FC = () => {
  const pathPrefix = useRouteMatch().url;
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { selectedPageCategory } = useSelectedAugurPage(pathPrefix);

  const { isHighlighted } = useTimeTravelHelpers(selectedPageCategory);

  const { data: activeModel, isLoading: isActiveModelLoading } = useActiveModel(
    habitatCode,
    augurCode
  );
  const {
    data: jobs,
    isLoading: isJobsLoading,
    error,
  } = useJobs(habitatCode, augurCode, JOB_STATUS.FINISHED);

  if (isActiveModelLoading || isJobsLoading) {
    return <Busy isBusy={true} />;
  }

  // calculate the reports to be displayed from the TimeTravel selection
  // this uses the same logic that is used for calculating the highlighted entries of the TimeTravel component
  const firstActiveJobCodeOfCurrentCategory =
    getFirstActiveJobCodeOfCurrentCategory(
      jobs.map(getTimeTravelEntryFromJob),
      selectedPageCategory,
      activeModel.code
    )?.code;
  const displayedJobs = jobs.filter((job) =>
    isHighlighted(job.jobCode, job.jobType, firstActiveJobCodeOfCurrentCategory)
  );

  return (
    <MainContainer
      fullWidth
      transparent
      additionalInnerClassname={styles.augurDetailsInner}
    >
      <OverlayAugurDetails />
      <InnerLiveAugur
        displayedReportCodes={displayedJobs.map((job) => job.reportCode)}
        pathPrefix={pathPrefix}
        hasNoModel={!activeModel.code}
      />
    </MainContainer>
  );
};

export type InnerProps = {
  displayedReportCodes: string[];
  pathPrefix: string;
  hasNoModel: boolean;
};

/**
 * Responsible for fetching the data for the ViewAugur.
 * @param displayedReportCodes
 * @param pathPrefix
 * @constructor
 */
export const InnerLiveAugur: FC<InnerProps> = ({
  displayedReportCodes,
  pathPrefix,
  hasNoModel,
}) => {
  const dispatch = useAppDispatch();
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isLoading: isAugurLoading } = useAugur(
    habitatCode,
    augurCode
  );
  const { mutate: mutateAugur } = useUpdateAugur(habitatCode, augurCode);
  // separate hook because of loading animation
  const { mutate: mutateArchiveAugur, isLoading: isArchiveAugurLoading } =
    useUpdateAugur(habitatCode, augurCode);

  const { data: augurSettings, isLoading: isAugurSettingsLoading } =
    useSettings(habitatCode, augurCode);
  const { mutate: mutateAugurSettings } = useUpdateSettings(
    habitatCode,
    augurCode
  );
  const {
    data: augurReportsData,
    isInitialLoading: isAugurReportsLoading,
    // fetch status is necessary because of dependant query
    fetchStatus: augurReportsFetchStatus,
  } = useReportsByCode(habitatCode, augurCode, displayedReportCodes);
  const augurReports = displayedReportCodes.length > 0 ? augurReportsData : [];

  const { data: modules, isInitialLoading: isModulesLoading } = useModules();

  // FIXME-CM type
  const getModuleConfig = (moduleCode?: string, moduleVersionCode?: string) => {
    return modules
      ?.find((module) => module.code === moduleCode)
      ?.versions?.find((version) => version.code === moduleVersionCode)
      ?.config as unknown as ModuleConfiguration;
  };

  if (
    isAugurLoading ||
    isAugurSettingsLoading ||
    (isAugurReportsLoading && augurReportsFetchStatus === 'fetching') ||
    isModulesLoading
  )
    return <Busy isBusy={true} />;

  // FIXME-CM why are these two different types in the first place
  const displayedAugurReports: AugurReport[] = augurReports.map((report) => ({
    modelCode: report.modelCode,
    jobCode: report.jobCode,
    // FIXME-CM type
    reportData: report.data as unknown as Record<
      string,
      Record<string, unknown>
    >,
    timestamp: report.createdAt,
    type: report.jobType,
  }));

  const displayedAugurSettings: AugurSettings = {
    general: {
      augurName: augur.name,
      module: {
        moduleCode: augur.moduleVersion.module.code,
        moduleVersionCode: augur.moduleVersion.code,
      },
      attributes: augur.attributes,
      ...augurSettings.resources,
    },
    settingsData: augurSettings.data,
  };

  // FIXME-CM type
  const config: ModuleConfiguration = augur.moduleVersion
    .config as unknown as ModuleConfiguration;

  return (
    <ViewAugur
      moduleConfig={config}
      getModuleConfig={getModuleConfig}
      augurReports={displayedAugurReports}
      augurSettings={displayedAugurSettings}
      pathPrefix={pathPrefix}
      isDevMode={false}
      onSubmitAugurSettings={(settings) => {
        const newModuleVersion = settings.general.module.moduleVersionCode;
        mutateAugur({
          name: settings.general.augurName,
          attributes: settings.general.attributes,
        });
        mutateAugurSettings({
          settingsData: settings.settingsData,
          resources: pickGeneralConfigurationResources(settings.general),
          moduleVersionCode: newModuleVersion,
          merge: false,
        });
      }}
      onArchiveAugur={(archive) => {
        mutateArchiveAugur(
          {
            archived: archive,
          },
          {
            onSuccess: () => {
              dispatch(
                sendNotification(
                  archive
                    ? 'notifications.title.augur_archived'
                    : 'notifications.title.augur_restored',
                  // @ts-ignore
                  archive
                    ? 'notifications.description.augur_has_been_archived'
                    : 'notifications.description.augur_has_been_restored',
                  event,
                  { augurCode, augurName: augur.name }
                )
              );
            },
          }
        );
      }}
      augurArchived={augur.archived}
      isArchiveButtonLoading={isArchiveAugurLoading}
      hasNoModel={hasNoModel}
    />
  );
};

export default LiveAugur;
