import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import GenericCollapsibleContainer from '../../../organisms/collapsible-container/generic/GenericCollapsibleContainer';
import Modal from '../../../organisms/modal/Modal';
import { ButtonProps } from '../../button/Button';
import ExpandCollapseIcon from '../../expand-collapse-icon/ExpandCollapseIcon';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';

export interface TextInputLineProps {
  description?: string | MessageDescriptor;
  label?: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  wrapMultipleErrorLines?: boolean;
  isCollapsible?: boolean;
  initialCollapsed?: boolean;
  onToggle?: (isCollapsed: boolean) => void;
  showInfo?: boolean;
}

// TODO: Ideally this component should not depend on FieldInputProps (but its rather convenient)
export type Props = TextInputLineProps & Partial<FieldInputProps<string>>;

// Don't use this directly, use the intld version
const TextInputLine: FC<DeIntl<Props>> = (props) => {
  const [showModal, setModalShown] = useState(false);

  const {
    description,
    placeholder,
    label,
    wrapMultipleErrorLines,
    isCollapsible = false,
    initialCollapsed = true,
    onToggle,
    showInfo,
    isDirty = false,
    invalid = false,
    isTouched = false,
    error,
    disabled,
    onChange,
    onBlur,
    name,
    inputRef,
    value,
  } = props;
  const fieldClassName = classNames(styles.TextInputLineInput, {
    [styles.error]: error,
    [styles.success]: !invalid && isDirty,
    // [styles.pending]: isValidating && isDirty,
  });

  const buttons: ButtonProps[] = [
    {
      color: 'white',
      onClick: () => {
        setModalShown(false);
      },
      label: 'Ok',
    },
  ];

  function renderBody() {
    return (
      <>
        <input
          type='text'
          className={fieldClassName}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          ref={inputRef}
          name={name}
          value={value || ''}
          aria-invalid={error ? 'true' : 'false'}
        />
        {!invalid && !disabled && isDirty && (
          <div className={styles.TextInputLineSuccess}>
            <span className='icon-ok' />
          </div>
        )}
      </>
    );
  }

  function renderHeader() {
    return (
      <>
        {label && <p className={styles.TextInputLineLabel}>{label}</p>}
        <div
          className={styles.TextInputLineError}
          style={{
            whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
          }}
        >
          {error && (
            <small className='InputError' title={error}>
              <span>{error}</span>
            </small>
          )}
        </div>
      </>
    );
  }

  function renderDefaultElement() {
    return (
      <div className={styles.TextInputLine}>
        <div className={styles.TextInputLineHeader}>
          {renderHeader()}
          {showInfo && (
            <MdInfoOutline
              size={17}
              style={{ marginLeft: 10 }}
              title={description}
              onClick={() => setModalShown(true)}
            />
          )}
          {showModal && (
            <Modal
              show={showModal}
              hideModal={() => setModalShown(false)}
              headline={{
                id: 'no-id',
                defaultMessage: 'Information',
              }}
              buttons={buttons}
            >
              <span>{description}</span>
            </Modal>
          )}
        </div>
        {renderBody()}
      </div>
    );
  }

  function renderCollapsibleElement() {
    const title = label ?? placeholder ?? '';
    return (
      <GenericCollapsibleContainer
        title={title ?? ''}
        initialCollapsed={initialCollapsed}
        onToggle={(isCollapsed) => onToggle?.(isCollapsed)}
        renderHeader={(title: string, collapsed: boolean) => (
          <div className={styles.Header}>
            <div className={styles.Icon}>
              <ExpandCollapseIcon isExpanded={!collapsed} onClick={() => {}} />
            </div>
            <div className={styles.TextInputLineHeader}>{renderHeader()}</div>
          </div>
        )}
        renderBody={() => (
          <div className={styles.CollapsibleHeadlineAreaBody}>
            <div className={styles.TextInputLine}>{renderBody()}</div>
          </div>
        )}
      />
    );
  }

  return isCollapsible ? renderCollapsibleElement() : renderDefaultElement();
};

export const IntlTextInputLine: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={TextInputLine} props={props} />;
};

export default IntlTextInputLine;
