import {
  RepositoryListing,
  RepoType,
  repoTypeToSpeaking,
} from 'common/dist/types/repository';
import { DeprecatedRootState } from '../../../../store/state.type';
import * as selector from '../../../../redux/selectors/user.selector';
import { DateFree } from 'common/dist/types/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { isApiError } from 'common/dist/constants/errors';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import Button from '../../../atoms/button/Button';
import msgsRepository from 'common/dist/messages/repository';
import msgsCommon from 'common/dist/messages/common';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ContentElement from './ContentElement';
import styles from './styles.module.scss';
import { DEFAULT_PAGE_SIZE } from './util';
import { useUserSummary } from '../../../../core/api/users';
import {
  useDeleteRepository,
  useRepositories,
} from '../../../../core/api/workbench/repositories';
import { hideDeleteRepoConfirm } from '../../../../redux/workbench/modules/collab.module';
import { useAppDispatch } from '../../../../store/store';
import Busy from '../../../atoms/busy/Busy';
import Paging, { PagingProps } from '../../../molecules/paging/Paging';
import ConfirmationModal from '../../../organisms/confirmation-modal/ConfirmationModal';
import { repositoryCreateLink, repositoryDetailsLink } from '../../routes';

interface RepositoriesProps {
  type: RepoType;
}

const Repositories: FC<RepositoriesProps> = ({ type }) => {
  const useData = (offset?: number, search?: string) =>
    useRepositories(type, offset, DEFAULT_PAGE_SIZE, search).data ?? [];

  return (
    <div className={'CollaborationSpace--content'}>
      <Paging
        itemsPerPage={DEFAULT_PAGE_SIZE}
        searchEnabled
        Headline={(currentPage, offset, limit, totalItemsOrCurrentItems) => (
          <Headline
            type={type}
            currentPage={currentPage}
            offset={offset}
            limit={limit}
            totalItemsOrCurrentItems={totalItemsOrCurrentItems}
          />
        )}
        useData={useData}
        InnerComponent={(props: PagingProps) => (
          <InnerComponent type={type} {...props} />
        )}
      />
    </div>
  );
};
const Headline: FC<
  {
    currentPage: number;
    offset: number;
    limit: number;
    totalItemsOrCurrentItems?: number;
  } & Pick<RepositoriesProps, 'type'>
> = ({ type }) => {
  const intl = useIntl();
  return (
    <div className={styles.headline}>
      <Button
        color={'secondary'}
        linkTo={repositoryCreateLink(type)}
        label={intl.formatMessage(msgsRepository.createButton, {
          type: repoTypeToSpeaking[type],
        })}
      />
    </div>
  );
};

const InnerComponent: FC<PagingProps & Pick<RepositoriesProps, 'type'>> = ({
  type,
  offset,
  search,
}) => {
  const { data, isError, error, isLoading } = useRepositories(
    type,
    offset,
    DEFAULT_PAGE_SIZE,
    search
  );
  const deleteRepoConfirm = useSelector<
    DeprecatedRootState,
    DeprecatedRootState['workbench']['collab']['deleteRepoConfirm']
  >((state) => state.workbench.collab.deleteRepoConfirm);
  const dispatch = useAppDispatch();
  const useDeleteRepo = useDeleteRepository(deleteRepoConfirm?.repoType);
  const nameShown =
    deleteRepoConfirm?.repoName !== undefined
      ? deleteRepoConfirm?.repoName
      : deleteRepoConfirm?.repoFullName;

  if (isError) {
    return (
      <div className={'repositories'}>
        <ErrorText error={error} />
      </div>
    );
  } else if (isLoading) {
    return <Busy isBusy={true} />;
  } else if (search && data?.length === 0) {
    return <NoSearchResults />;
  } else if (offset === 0 && data?.length === 0) {
    return <Empty type={type} />;
  } else {
    return (
      <>
        <div className={'repositories'}>
          {(data ?? []).map((repo, i) => (
            <WrappedContentElement repo={repo} i={i} />
          ))}
        </div>

        <ConfirmationModal
          show={deleteRepoConfirm?.show}
          payload={deleteRepoConfirm?.repoFullName}
          secure={true}
          secureInput={nameShown}
          headlineColor={'red'}
          headline={msgsRepository.deleteRepositoryModalHeadline}
          description={{
            ...msgsRepository.deleteRepositoryModalDescription,
            values: { nameShown: <code>{nameShown}</code> },
          }}
          buttonConfirmColor={'red'}
          buttonConfirmText={msgsCommon.delete}
          buttonConfirmAction={(repoFullName: string) =>
            useDeleteRepo.mutate({ repoFullName })
          }
          hideModal={() => dispatch(hideDeleteRepoConfirm())}
        />
      </>
    );
  }
};

/**
 * Its own component to fetch and display the userSummary
 * @param repo
 * @param i
 * @constructor
 */
const WrappedContentElement: FC<{
  repo: DateFree<RepositoryListing>;
  i: number;
}> = ({ repo, i }) => {
  const currentUserId = useSelector(selector.currentUser).id;

  // Gitea specific
  const groupName = repo.owner?.username;
  const user = useUserSummary(groupName)?.data;
  const createdBy = useUserSummary(repo.createdByUserId)?.data;
  const speakingOwner =
    user === undefined ? undefined : `${user.firstName} ${user.lastName}`;
  return (
    <Link
      key={i}
      to={repositoryDetailsLink(repo.full_name)}
      style={{ textDecoration: 'none' }}
    >
      <ContentElement
        {...repo}
        name={repo.name}
        repoDescription={repo.repoDescription}
        speakingOwner={speakingOwner}
        currentUserId={currentUserId}
        createdBy={createdBy}
      />
    </Link>
  );
};

const ErrorText: FC<{ error: ApiError | Error | unknown }> = ({ error }) => {
  if (isApiError(error)) {
    return <FormattedMessage {...error.formattedMessage} />;
  } else if (error instanceof Error) {
    return <span>{error.message}</span>;
  } else {
    return <span>{JSON.stringify(error)}</span>;
  }
};

const Empty: FC<Pick<RepositoriesProps, 'type'>> = ({ type }) => {
  const intl = useIntl();
  return (
    <IndicatorEmpty
      classNameImage={'git-repositories-empty-pic'}
      headline={{
        ...msgsRepository.noRepositoriesTitle,
        values: { type: repoTypeToSpeaking[type] },
      }}
      description={{
        ...msgsRepository.noRepositoriesDescription,
        values: { type: repoTypeToSpeaking[type] },
      }}
      Actions={() => (
        <Button
          color={'secondary'}
          linkTo={repositoryCreateLink(type)}
          label={intl.formatMessage(msgsRepository.createButton, {
            type: repoTypeToSpeaking[type],
          })}
        />
      )}
    />
  );
};

const NoSearchResults: FC = () => {
  return (
    <IndicatorEmpty
      classNameImage={'git-repositories-empty-pic'}
      headline={msgsCommon.noSearchResultsTitle}
      description={msgsCommon.noSearchResultsDescription}
    />
  );
};
export default Repositories;
