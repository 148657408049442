import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import GenericCollapsibleContainer from '../../../organisms/collapsible-container/generic/GenericCollapsibleContainer';
import Modal from '../../../organisms/modal/Modal';
import { ButtonProps } from '../../button/Button';
import ExpandCollapseIcon from '../../expand-collapse-icon/ExpandCollapseIcon';
import InputError from '../../input-error/InputError';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';

export type TextInputAreaProps = {
  className?: string;
  label: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  amountRows?: number;
  isCollapsible?: boolean;
  initialCollapsed?: boolean;
  onToggle?: (isCollapsed: boolean) => void;
  description?: string;
  showInfo?: boolean; //Shows the info icon on the right
};

// TODO: Ideally this component should not depend on FieldInputProps (but its rather convenient)
export type Props = TextInputAreaProps & Partial<FieldInputProps<string>>;

export const TextInputArea: FC<DeIntl<Props>> = (props) => {
  const [showModal, setModalShown] = useState(false);

  const {
    label,
    placeholder,
    amountRows = 5,
    className,
    isCollapsible,
    initialCollapsed = true,
    onToggle,
    description,
    showInfo,
    error,
    disabled,
    onChange,
    isTouched = false,
    value,
    onBlur,
    inputRef,
    name,
  } = props;

  const fieldClassName = classNames(
    styles.TextInputAreaInput,
    className,
    isTouched && {
      [styles.error]: error,
    }
  );

  function renderBody() {
    return (
      <textarea
        rows={amountRows}
        onChange={onChange}
        onBlur={onBlur}
        className={fieldClassName}
        placeholder={placeholder}
        disabled={disabled}
        style={{ resize: 'none' }}
        ref={inputRef}
        value={value || ''}
        aria-invalid={error ? 'true' : 'false'}
        name={name}
      />
    );
  }

  const buttons: ButtonProps[] = [
    {
      color: 'white',
      onClick: () => {
        setModalShown(false);
      },
      label: 'Ok',
    },
  ];

  function renderHeader() {
    return (
      <div className={styles.TextInputAreaHeader}>
        {label && <p className={styles.TextInputAreaLabel}>{label}</p>}
        {showInfo && (
          <MdInfoOutline
            size={17}
            style={{ marginLeft: 10 }}
            title={description}
            onClick={() => setModalShown(true)}
          />
        )}
        {showModal && (
          <Modal
            show={showModal}
            hideModal={() => setModalShown(false)}
            headline={{
              id: 'no-id',
              defaultMessage: 'Information',
            }}
            buttons={buttons}
          >
            <span>{description}</span>
          </Modal>
        )}
        {error && (
          <div className={styles.TextInputAreaError}>
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  function renderDefaultElement() {
    return (
      <div className={styles.TextInputArea}>
        {renderHeader()}
        {renderBody()}
      </div>
    );
  }

  function renderCollapsible() {
    return (
      <GenericCollapsibleContainer
        title={label}
        initialCollapsed={initialCollapsed}
        onToggle={(isCollapsed) => onToggle && onToggle(isCollapsed)}
        renderHeader={(title: string, collapsed: boolean) => (
          <div className={styles.Header}>
            <div className={styles.Icon}>
              <ExpandCollapseIcon isExpanded={!collapsed} onClick={() => {}} />
            </div>
            <div className={styles.TextInputArea}>{renderHeader()}</div>
          </div>
        )}
        renderBody={() => <div className={styles.Body}>{renderBody()}</div>}
      />
    );
  }

  return isCollapsible ? renderCollapsible() : renderDefaultElement();
};
export const IntlTextInputArea: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={TextInputArea} props={props} />;
};

export default IntlTextInputArea;
