import { JobType, JobTypeToSpeaking } from 'common/dist/types/job';
import {
  GeneralConfiguration,
  K8sResources,
} from 'common/dist/types/moduleVersion';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormReturn } from 'react-hook-form';

import styles from './styles.module.scss';
import { useNodeInfo } from '../../../../core/api/codeCapsules';
import { K8sResourcesSelect } from '../../../molecules/k8s-resources-select/K8sResourcesSelect';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';
import { ModuleConfiguration } from '../../../pages/augur/type';
import { AugurSettingsForm } from '../../../pages/augur/utils/augurSettings.form';
import Busy from '../../busy/Busy';
import { ButtonProps } from '../../button/Button';

function switchDisabled(
  category: string,
  formWatcher: GeneralConfiguration | K8sResources | AugurSettingsForm,
  control: Control<
    GeneralConfiguration | K8sResources | AugurSettingsForm,
    'GeneralConfiguration' | 'K8sResources' | 'AugurSettingsForm'
  >,
  generalConfiguration: GeneralConfiguration
): boolean {
  const type = control._options.context;
  const config =
    type === 'GeneralConfiguration'
      ? (formWatcher as GeneralConfiguration)
      : generalConfiguration;

  switch (category) {
    case 'learning':
      return config.supportsLearning;
    case 'evaluation':
      return config.supportsEvaluation;
    case 'prediction':
      return config.supportsPrediction;
    case 'realtimePrediction':
      return config.supportsRealtimePrediction;
    default:
      return false;
  }
}

export const jobs: JobType[] = [
  'learning',
  'evaluation',
  'prediction',
  'realtimePrediction',
];
type Props = {
  jobs: JobType[];
  form: UseFormReturn<
    GeneralConfiguration | K8sResources | AugurSettingsForm,
    'GeneralConfiguration' | 'K8sResources' | 'AugurSettingsForm'
  >;
};

export const ResourceInput: FC<Props & Partial<ModuleConfiguration>> = (
  props
) => {
  const { jobs, generalConfiguration, form } = props;
  const [activeJobTab, setActiveJobTab] = useState<JobType | ''>('');

  const formWatcher = form?.watch();

  useEffect(() => {
    if (
      _.isEmpty(activeJobTab) &&
      (('supportsLearning' in formWatcher && formWatcher.supportsLearning) ||
        generalConfiguration?.supportsLearning)
    ) {
      setActiveJobTab(jobs?.[0]);
    }
  }, [formWatcher, generalConfiguration]);
  //If the user is currently on a tab and disables the supports flag for exactly this tab he will be send to the first tab
  if ('supportsPrediction' in formWatcher) {
    if (
      (!formWatcher.supportsRealtimePrediction &&
        activeJobTab === 'realtimePrediction') ||
      (!formWatcher.supportsPrediction && activeJobTab === 'prediction') ||
      (!formWatcher.supportsEvaluation && activeJobTab === 'evaluation')
    ) {
      setActiveJobTab(jobs?.[0]);
    }
  }
  //Create the buttons for the tabs to show
  const buttons = jobs.map((category) => {
    let enabled = false;
    if (generalConfiguration && formWatcher)
      enabled = switchDisabled(
        category,
        formWatcher,
        form.control,
        generalConfiguration
      );
    return {
      id: category,
      label: JobTypeToSpeaking[category],
      onClick: () => {
        setActiveJobTab(category);
      },
      disabled: !enabled,
    } satisfies ButtonProps;
  });
  const { data: nodeInfo, isLoading } = useNodeInfo();
  if (!nodeInfo) return <Busy isBusy={isLoading} />;
  return 'supportsLearning' in formWatcher && !formWatcher.supportsLearning ? (
    <div>No resource configuration available.</div>
  ) : (
    <div>
      <TabLineBubbles
        design={'tabs'}
        buttons={buttons}
        activeButton={activeJobTab}
      />
      <div className={styles.ModelSettingsCategory}>
        {activeJobTab &&
          jobs.map((category, key) => {
            return (
              <div
                key={key}
                style={{
                  display: activeJobTab === category ? 'block' : 'none',
                }}
              >
                <K8sResourcesSelect
                  nodeInfo={nodeInfo}
                  headerLabel={`${JobTypeToSpeaking[category]} Resources`}
                  activeCategory={category}
                  // @ts-ignore FIXME-CM
                  form={form}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ResourceInput;
