import classNames from 'classnames';
import layoutElementMessages from 'common/dist/messages/augurs.elements';
import React, { FC, useEffect, useState } from 'react';
import { MdFullscreen, MdFullscreenExit, MdInfoOutline } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';
import Modal from '../../../../organisms/modal/Modal';
import { AugurReportElementProps } from '../../../../pages/augur/type';
import ErrorBoundary from '../../../../pages/error-boundary/ErrorBoundary';
import commonParentStyles from '../../common/styles.module.scss';
import MultiReportWrapper from '../multi-report-wrapper/MultiReportWrapper';
import { AugurReportElementMeta } from '../types/meta';

export type Props = {
  setFullPage?: (fullPageElement: null | string) => void;
  fullPageElement?: string | null;
  elementProps: AugurReportElementProps;
  elementMeta: AugurReportElementMeta;
  /** Flag for edit mode to disable any interactive functionality. */
  editMode?: boolean;
};

/**
 * Mostly a layout element. Not much logic. Should work for ViewAugur and EditAugur
 *
 * @param props
 * @constructor
 */
const ReportParentElement: FC<Props> = (props) => {
  const {
    elementProps,
    elementMeta,
    editMode = false,
    fullPageElement,
    setFullPage,
  } = props;
  // Both config and reportData can be invalid

  const { title, description, config, reportEntries } = elementProps;
  const [showModal, setModalShown] = useState(false);
  const [layoutEditorElement, setLayoutEditorElement] = useState<HTMLElement>();

  // get portal target after rendering is finished
  useEffect(() => {
    setLayoutEditorElement(
      document.getElementById('layoutEditorContainer') ?? undefined
    );
  }, []);

  function renderContent() {
    const { SingleReportComponent, MultiReportComponent } = elementMeta;

    if (reportEntries.length === 0) {
      return <FormattedMessage {...layoutElementMessages.elementNoReports} />;
    }

    if (reportEntries.length === 1) {
      if (!reportEntries[0].reportValue) {
        return (
          <FormattedMessage
            {...layoutElementMessages.elementInvalidReportData}
          />
        );
      }

      return (
        <SingleReportComponent
          config={config}
          input={reportEntries[0]}
          portalTarget={layoutEditorElement}
        />
      );
    } else {
      if (MultiReportComponent) {
        return (
          <MultiReportComponent
            config={config}
            input={reportEntries}
            portalTarget={layoutEditorElement}
          />
        );
      } else {
        return (
          <MultiReportWrapper
            elementProps={{
              config: config,
              input: reportEntries,
              portalTarget: layoutEditorElement,
            }}
            elementMeta={elementMeta}
          />
        );
      }
    }
  }

  function renderContainer() {
    return (
      <div className={commonParentStyles.parentContainer}>
        <div className={commonParentStyles.headerContainer}>
          {title && (
            <div className={commonParentStyles.title} title={title}>
              {title}
            </div>
          )}
          {description && (
            <MdInfoOutline
              size={17}
              className={commonParentStyles.infoIcon}
              title={description}
              onClick={() => !editMode && setModalShown(true)}
            />
          )}
          {showModal && (
            <Modal
              show={showModal}
              hideModal={() => setModalShown(false)}
              headline={{
                id: 'no-id',
                defaultMessage: 'Information',
              }}
              buttons={[
                {
                  color: 'white',
                  onClick: () => {
                    setModalShown(false);
                  },
                  label: 'Ok',
                },
              ]}
            >
              <span>{description}</span>
            </Modal>
          )}
          {!editMode && (
            <div
              className={styles.fullScreen}
              onClick={() =>
                setFullPage?.(
                  fullPageElement === null ? elementProps.uuid : null
                )
              }
            >
              {fullPageElement ? (
                <MdFullscreenExit size={21} />
              ) : (
                <MdFullscreen size={21} />
              )}
            </div>
          )}
        </div>
        <ErrorBoundary fullScreenHeight={false}>
          <div
            className={classNames(commonParentStyles.content, styles.chart)}
            style={{ pointerEvents: editMode ? 'none' : 'auto' }}
          >
            {renderContent()}
          </div>
        </ErrorBoundary>
      </div>
    );
  }

  return renderContainer();
};

export default ReportParentElement;
