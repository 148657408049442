import { useQueryClient } from '@tanstack/react-query';
import commonMessages from 'common/dist/messages/common';
import moduleMsgs from 'common/dist/messages/modules';
import {
  GeneralConfiguration,
  SERVING_TYPES,
} from 'common/dist/types/moduleVersion';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import styles from './styles.module.scss';
import { modulesKeys, useModuleFiles } from '../../../core/api/modules';
import { saveConfig } from '../../../core/api/workbench/content';
import Button from '../../atoms/button/Button';
import { IntlCheckbox } from '../../atoms/react-hook-form-input-elements/checkbox/Checkbox';
import {
  jobs,
  ResourceInput,
} from '../../atoms/react-hook-form-input-elements/resource-input/ResourceInput';
import { useSelectedDirPath } from '../../workbench/hooks';
import { baseConfig } from '../augur/config.template';
import { ModuleConfiguration } from '../augur/type';

export type Props = {
  notebookUser: string;
};

const GeneralConfigurationPage: FC<Props> = (props) => {
  const { notebookUser } = props;
  const selectedDirPath = useSelectedDirPath();
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  const queryClient = useQueryClient();

  const { data: moduleFilesData } = useModuleFiles(
    contentArrayToPath(selectedDirPath.slice(0, index + 1), false),
    index !== -1
  );
  //Local variables to simplify the tree
  const actualConfig = moduleFilesData?.config
    ? moduleFilesData.config
    : baseConfig;

  // construct config form
  const configFormMethods = useForm<
    GeneralConfiguration,
    'GeneralConfiguration'
  >({
    mode: 'all',
    values: {
      ...actualConfig?.generalConfiguration,
    },
  }); // We explicitly do NOT use defaultValues here (see below)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = configFormMethods;
  //We need to set the context for the complex key situation here
  control._options.context = 'GeneralConfiguration';

  const supportsLearning = watch('supportsLearning');

  async function invalidateModuleFilesData() {
    await queryClient.invalidateQueries(
      modulesKeys.files(
        contentArrayToPath(selectedDirPath.slice(0, index + 1), false)
      )
    );
  }

  const onSubmit: SubmitHandler<GeneralConfiguration> = async (config) => {
    //OnSubmit we check if a checkbox was removed and clear the key in that case
    if (!config.supportsLearning) {
      //We have to specifically set all values to undefined when the learningjob is not supported
      config.learning = undefined;
      config.evaluation = undefined;
      config.prediction = undefined;
      config.realtimePrediction = undefined;
      config.supportsLearning = undefined;
      config.supportsPrediction = undefined;
      config.supportsEvaluation = undefined;
      config.supportsRealtimePrediction = undefined;
    }
    if (!config.supportsEvaluation) {
      config.evaluation = undefined;
    }
    if (!config.supportsPrediction) {
      config.prediction = undefined;
    }
    if (!config.supportsRealtimePrediction) {
      config.realtimePrediction = undefined;
    } else {
      if (!config.realtimePrediction) {
        console.error(
          'config.realtimePrediction was empty, this is unexpected'
        );
        // @ts-ignore The form state does not actually match the config
        config.realtimePrediction = {};
      }
      // This is currently totally dependent on supportsRealtimePrediction. If yes then currently the availableServingTypes must be ["rest"]
      //  That's why this is not a field itself
      config.realtimePrediction.availableServingTypes = [SERVING_TYPES.REST];
    }
    const newConfig: ModuleConfiguration = {
      ...actualConfig,
      generalConfiguration: config,
    };
    await saveConfig(notebookUser, newConfig, selectedDirPath, index).then(
      invalidateModuleFilesData
    );

    // Reset the form after successful submission
    reset(newConfig.generalConfiguration);
  };
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <span>General Configuration</span>
      </div>

      <FormProvider {...configFormMethods}>
        <form
          className={styles.devAugurContainer}
          onSubmit={handleSubmit(onSubmit)}
          // prevent submission on enter
          onKeyDown={(e) => {
            e.key === 'Enter' &&
              !(e.target instanceof HTMLTextAreaElement) &&
              e.preventDefault();
          }}
        >
          <div className={styles.HeaderLeft}>
            <div className={styles.FullLine} />
            <span>Jobs</span>
          </div>
          <Controller
            name={'supportsLearning'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={moduleMsgs.moduleConfigurationPageSupportsLearning}
                />
              );
            }}
          />
          <Controller
            name={'supportsEvaluation'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={moduleMsgs.moduleConfigurationPageSupportsEvaluation}
                  disabled={!supportsLearning}
                />
              );
            }}
          />{' '}
          <Controller
            name={'supportsPrediction'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={moduleMsgs.moduleConfigurationPageSupportsPrediction}
                  disabled={!supportsLearning}
                />
              );
            }}
          />{' '}
          <Controller
            name={'supportsRealtimePrediction'}
            control={control}
            render={({ field, fieldState, formState }) => {
              const { ref, value, ...rest } = field; // extract ref to pass as inputRef
              return (
                <IntlCheckbox
                  {...rest}
                  {...fieldState}
                  {...formState}
                  checked={value}
                  inputRef={ref}
                  label={
                    moduleMsgs.moduleConfigurationPageSupportsRealtimePrediction
                  }
                  disabled={!supportsLearning}
                />
              );
            }}
          />
          <div className={styles.HeaderLeft}>
            <div className={styles.FullLine} />
            <span>Resources</span>
          </div>
          <ResourceInput
            jobs={jobs}
            {...actualConfig}
            //@ts-ignore FIXME-CM
            form={configFormMethods}
          />
          <div className={styles.ButtonsContainer}>
            <Button
              color={'primary'}
              disabled={Object.keys(dirtyFields).length === 0 || !isValid}
              label={commonMessages.submit}
              type={'submit'}
            />
            <Button
              color={'white'}
              label={commonMessages.cancel}
              onClick={() => reset(actualConfig.generalConfiguration)}
              disabled={!isDirty}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralConfigurationPage;
