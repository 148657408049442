import { defineMessages } from 'react-intl';

export default defineMessages({
  msgPerformanceDriftSliceWarning: {
    id: 'augur.performance_drift.evaluation_reports',
    defaultMessage:
      'Showing only the { limit } most recent evaluation reports of your selection',
  },
  // --- Step titles and messages
  msgTitleNameStep: {
    id: 'newAugur.steps.name.title',
    defaultMessage: 'Augur Name',
  },

  msgDescriptionNameStep: {
    id: 'newAugur.steps.name.description',
    defaultMessage: 'Give your Augur a unique name',
  },

  msgTitleModuleTypeStep: {
    id: 'newAugur.steps.moduleType.title',
    defaultMessage: 'Module',
  },

  msgDescriptionModuleTypeStep: {
    id: 'newAugur.steps.moduleType.description',
    defaultMessage: 'Select the use case of your new Augur',
  },
  msgSelectArchetypeTitle: {
    id: 'newAugur.stepTwo.select_a_archetype',
    defaultMessage: 'Select an Archetype',
  },
  msgSelectArchetypeDescription: {
    id: 'newAugur.stepTwo.select_a_archetype_for_your_augur',
    defaultMessage:
      'Select an Archetype for your Augur. The Archetype is the actual algorithm used for modelling.',
  },
  msgSamplingStrategyTitle: {
    id: 'newAugur.steps.samplingStrategy.title',
    defaultMessage: 'Sampling Strategy',
  },
  msgSamplingStrategyDescription: {
    id: 'newAugur.steps.samplingStrategy.description',
    defaultMessage: 'Set the Sampling Strategy',
  },
  msgTrainingTableTitle: {
    id: 'newAugur.steps.training_table.title',
    defaultMessage: 'Training Table',
  },
  msgTrainingTableDescription: {
    id: 'newAugur.steps.training_table.description',
    defaultMessage: 'Select the Training Table.',
  },
  msgTrainingTableTable: {
    id: 'newAugur.steps.training_table.select_training_table',
    defaultMessage: 'Select the Training Table',
  },
  msgTrainingTablePlaceholderTable: {
    id: 'newAugur.steps.training_table.no_table_selected',
    defaultMessage: 'No Table selected',
  },
  msgTrainingTableLabelColumn: {
    id: 'newAugur.steps.training_table.select_label_column',
    defaultMessage: 'Select the Label Column',
  },
  msgTrainingTablePlaceholderLabelColumn: {
    id: 'newAugur.steps.training_table.no_label_column_selected',
    defaultMessage: 'No Label Column selected',
  },
  msgTrainingTableIdColumns: {
    id: 'newAugur.steps.training_table.select_id_columns',
    defaultMessage: 'Select the ID Column(s)',
  },
  msgTrainingTablePlaceholderIdColumns: {
    id: 'newAugur.steps.training_table.no_id_column_selected',
    defaultMessage: 'No ID Columns selected',
  },
  msgTrainingTablePositiveLabel: {
    id: 'newAugur.steps.training_table.positive_label_value',
    defaultMessage: 'Enter the positive Label value',
  },
  msgTrainingTablePlaceholderPositiveLabel: {
    id: 'newAugur.steps.training_table.no_positive_label',
    defaultMessage: 'No positive Label Value',
  },
  msgTrainingTableNegativeLabel: {
    id: 'newAugur.steps.training_table.negative_label_value',
    defaultMessage: 'Enter the negative Label value',
  },
  msgTrainingTablePlaceholderNegativeLabel: {
    id: 'newAugur.steps.training_table.no_negative_label',
    defaultMessage: 'No negative Label Value',
  },
  msgPredictionTableTitle: {
    id: 'newAugur.steps.prediction_table.title',
    defaultMessage: 'Optional: Prediction Table',
  },
  msgPredictionTableDescription: {
    id: 'newAugur.steps.prediction_table.description',
    defaultMessage: 'Select the Prediction Table.',
  },
  msgPredictionTableNotSelected: {
    id: 'newAugur.steps.prediction_table.no_table_selected',
    defaultMessage: 'No Table selected',
  },
  msgTuningPipelineTitle: {
    id: 'newAugur.steps.tuningPipeline.title',
    defaultMessage: 'Pipeline Tuning',
  },
  msgTuningPipelineDescription: {
    id: 'newAugur.steps.tuningPipeline.description',
    defaultMessage: 'Set the parameters for the Pipeline Tuning',
  },
  msgTuningParameterTitle: {
    id: 'newAugur.steps.tuningParameter.title',
    defaultMessage: 'Parameter Tuning',
  },
  msgTuningParameterDescription: {
    id: 'newAugur.steps.tuningParameter.description',
    defaultMessage: 'Tune the Parameters for Training',
  },
  msgPredictionTarget: {
    id: 'newAugur.stepTwo.your_augur_prediction_target',
    defaultMessage: 'Your Augur Prediction Target',
  },
  msgDictionaryTitle: {
    id: 'newAugur.dictionary.title',
    defaultMessage: 'Dictionary',
  },
  msgDictionaryDescription: {
    id: 'newAugur.dictionary.description',
    defaultMessage: 'Enter the key-value pairs for your tagger dictionary.',
  },
  augurWaitForLearning: {
    id: 'augur.wait_for_learning',
    defaultMessage:
      'The Augur has either not yet completed its first learning session or another job has been selected.',
  },
  augurWaitForEvaluation: {
    id: 'augur.wait_for_evaluation',
    defaultMessage:
      'The Augur has either not yet completed its first evaluation session or another model has been selected.',
  },
  augurWaitForPrediction: {
    id: 'augur.wait_for_prediction',
    defaultMessage:
      'The Augur has either not yet completed its first prediction session or another model has been selected.',
  },
  msgModelViewerBinaryTreeSeeMore: {
    id: 'augur.details.modelViewer.click_nodes_to_see_more_details',
    defaultMessage: 'Click Nodes to see more details.',
  },
  msgModelViewerBinaryTreeTooltipTotalInstances: {
    id: 'augur.details.modelViewer.chart.tree.tooltip.total_instances',
    defaultMessage: 'Total Instances',
  },
  msgModelViewerBinaryTreeTooltipTotalPercentage: {
    id: 'augur.details.modelViewer.chart.tree.tooltip.total_percent',
    defaultMessage: 'Total Percent',
  },

  msgModelViewerBinaryTreeNodeTooltipConfidence: {
    id: 'augur.details.modelViewer.chart.tree.tooltip.confidence',
    defaultMessage: 'Confidence {className}',
  },
  msgModelViewerBinaryTreeNodeTooltipInstances: {
    id: 'augur.details.modelViewer.chart.tree.tooltip.instances',
    defaultMessage: 'Instances {className}',
  },
  msgModelViewerBinaryTreeNodeTooltipNegativeClass: {
    id: 'augur.details.modelViewer.chart.tree.tooltip.negative_class',
    defaultMessage: 'Class 0',
  },
  msgModelViewerBinaryTreeNodeTooltipPositiveClass: {
    id: 'augur.details.modelViewer.chart.tree.tooltip.positive_class',
    defaultMessage: 'Class 1',
  },
  tuningPipelineValidationErrorNoClassifier: {
    id: 'augur.tuning_pipeline.validation_error.no_classifier',
    defaultMessage: 'At least one classifier must be activated',
  },
  tuningPipelineValidationErrorUnspecified: {
    id: 'augur.tuning_pipeline.validation_error.unspecified',
    defaultMessage: 'See nodes for concrete errors',
  },
  stepInputFormatErrorMissingInputFormat: {
    id: 'augur.stepInputFormat.error.missing_input_format',
    defaultMessage: 'Please select an Input Format',
  },
  stepInputFormatTitle: {
    id: 'augur.stepInputFormat.title',
    defaultMessage: 'Select Input Format',
  },
  stepInputFormatDescription: {
    id: 'augur.stepInputFormat.description',
    defaultMessage: 'Select the Input format for your Datapool',
  },
  stepInputFormatPlaceholder: {
    id: 'augur.stepInputFormat.placeholder',
    defaultMessage: 'No Input Format selected',
  },
  stepDataSourceErrorMissingDatasourceCode: {
    id: 'augur.stepDataSource.error.missing_datasource_code',
    defaultMessage:
      'Please select a Data Source as storage backend for your Datapool',
  },
  stepDataSourceLabel: {
    id: 'augur.stepDataSource.label',
    defaultMessage: 'Select Data Source',
  },
  stepDataSourcePlaceholder: {
    id: 'augur.stepDataSource.placeholder',
    defaultMessage: 'No Data Source selected',
  },

  augurNew: {
    id: 'augur.new',
    defaultMessage: 'New Augur',
  },
});
