import classNames from 'classnames';

import { JOB_GROUP_VARIANTS } from '../../../orchestration/details/job-group-details/JobGroupDetails';
import styles from './styles.module.scss';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { JobGroupType } from 'common/dist/types/job';
import React, { FC } from 'react';
import { FiRepeat, FiStopCircle } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { connectedProps } from './JobGroupExecution.container';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon';
import Button from '../../../atoms/button/Button';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../../atoms/formatted-date-time/FormattedDateTime';
import { ORCHESTRATION_ORIGINS } from '../../../orchestration/links';
import { orchestrationRoutes } from '../../../orchestration/routes';
import ConfirmationModal from '../../../organisms/confirmation-modal/ConfirmationModal';
import JobGroupTopologyChart from '../job-group-topology-chart/JobGroupTopologyChart';

export type Props = {
  jobGroupCode: string;
  jobGroup: JobGroupType;
  /** Which origin should be set if the details are shown on click */
  detailsOrigin: string;
  cancelJobGroup: (jobGroupCode: string) => void;
  runJobGroup: (jobGroup) => void;
};

const JobGroupExecution: FC<Props & connectedProps> = ({
  jobGroup,
  augurNames,
  codeCapsuleNames,
  detailsOrigin,
  setDetailsOrigin,
  cancelJobGroup,
  hideJobGroupConfirm,
  cancelJobGroupConfirm,
  showJobGroupConfirm,
  runJobGroup,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const addedToPreQueue = new Date(jobGroup.addedToPreQueue);
  const finished = new Date(jobGroup.finished);

  return (
    <>
      <div
        className={classNames(
          styles.item,
          detailsOrigin && detailsOrigin === ORCHESTRATION_ORIGINS.queue
            ? styles.queue
            : styles.history
        )}
        onClick={() => {
          setDetailsOrigin(history.location.pathname);
          history.push(
            `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobHistory}/${jobGroup.code}`
          );
        }}
      >
        <div
          className={styles.topology}
          style={{
            gridRow: '1',
            gridColumn: '1',
          }}
        >
          <JobGroupTopologyChart
            jobs={jobGroup.jobs}
            jobGroupTopology={jobGroup.jobGroupTopology}
            showJobStatus={true}
            onClick={(jobCode) => {
              history.push(
                `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}`
              );
              setDetailsOrigin(detailsOrigin);
            }}
            augurNames={augurNames}
            codeCapsuleNames={codeCapsuleNames}
            slim
          />
        </div>
        <div
          className={styles.info}
          style={{
            gridRow: '1',
            gridColumn: '2',
          }}
        >
          <strong className={styles.field}>{jobGroup.name || ''}</strong>
          <div className={styles.description}>
            <div className={styles.box}>{jobGroup.description || ''}</div>
            <div className={styles.tooltip}>
              <span className={styles.tooltipText}>
                {jobGroup.description || ''}
              </span>
            </div>
          </div>
        </div>
        <div
          className={styles.info}
          style={{
            gridRow: '1',
            gridColumn: '3',
          }}
        >
          <strong className={styles.field}>
            <FormattedRelativeTimeConvenient
              date={addedToPreQueue}
              tooltip={false}
            />
          </strong>
          <span className={styles.field}>
            <FormattedDateTime date={addedToPreQueue} />
          </span>
        </div>
        <div
          className={styles.userIcon}
          style={{
            gridRow: '1',
            gridColumn: '4',
          }}
        >
          {jobGroup.createdBy && (
            <AvatarIconContainer userId={jobGroup.createdBy} />
          )}
        </div>
        <div
          className={styles.info}
          style={{
            gridRow: '1',
            gridColumn: '5',
          }}
        >
          {!isNaN(finished.valueOf()) && finished.valueOf() > 0 && (
            <>
              <strong className={styles.field}>
                <FormattedRelativeTimeConvenient
                  date={finished}
                  tooltip={false}
                />
              </strong>
              <span className={styles.field}>
                <FormattedDateTime date={finished} />
              </span>
            </>
          )}
        </div>
        {detailsOrigin && detailsOrigin === ORCHESTRATION_ORIGINS.queue && (
          <div
            className={styles.buttons}
            style={{
              gridRow: '1',
              gridColumn: '5',
            }}
          >
            <div
              className={styles.buttonsWrapper}
              onClick={(evt) => evt.stopPropagation()}
            >
              <Button
                Icon={() => <FiStopCircle size={18} />}
                form={'squared'}
                onClick={() => showJobGroupConfirm(jobGroup.code)}
                title={orchestrationMessages.jobGroupCancel}
              />
            </div>
          </div>
        )}
        {detailsOrigin &&
          jobGroup.status !== 'successful' &&
          detailsOrigin === ORCHESTRATION_ORIGINS.history && (
            <div
              className={styles.buttons}
              style={{
                gridRow: '1',
                gridColumn: '6',
              }}
            >
              <div
                className={styles.buttonsWrapper}
                onClick={(evt) => evt.stopPropagation()}
              >
                <Button
                  Icon={() => <FiRepeat size={18} />}
                  form={'squared'}
                  onClick={() => {
                    // Set the status of the Jobs that are supposed to be run again to "waiting"
                    //   otherwise the Orchestration won't execute them again.
                    const resettedJobs = jobGroup.jobs.map((j) => {
                      if (['failure', 'cancelled'].includes(j.status)) {
                        return {
                          ...j,
                          status: 'waiting',
                        };
                      } else {
                        return j;
                      }
                    });
                    const restartJobGroup = {
                      name: jobGroup.name,
                      description: jobGroup.description,
                      jobs: resettedJobs,
                      jobGroupTopology: jobGroup.jobGroupTopology,
                      priority: jobGroup.priority.toLowerCase(),
                      trigger: jobGroup.trigger.toLowerCase(),
                    };
                    runJobGroup({
                      jobGroup: restartJobGroup,
                    });
                  }}
                  title={orchestrationMessages.jobGroupRetryFailed}
                />
              </div>
            </div>
          )}
      </div>
      <ConfirmationModal
        secure={false}
        show={cancelJobGroupConfirm?.show}
        payload={{ jobGroupCode: cancelJobGroupConfirm?.jobGroupCode }}
        buttonConfirmAction={(jobGroupCode) => {
          // Same input as payload above
          cancelJobGroup(jobGroupCode);
        }}
        hideModal={hideJobGroupConfirm}
        buttonConfirmText={orchestrationMessages.jobGroupCancel}
        buttonConfirmColor={'red'}
        headline={orchestrationMessages.jobGroupCancel}
        headlineColor={'red'}
      />
    </>
  );
};

export default JobGroupExecution;
